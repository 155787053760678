import React from "react"
import Base from "../../components/templates/Base"

import AboutYourBusiness from "../../components/SignUp/AboutYourBusiness";

const SignUpPage = () => {
  return (
    <Base>
      <AboutYourBusiness />
    </Base>
  );
};

export default SignUpPage;