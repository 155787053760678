import React from "react"

import * as SignUpStyles from "./signup.module.scss"
import { Link } from "gatsby";

import BarRestaurantIcon from "../../images/s-bar-restaurant.svg"
import BarIcon from "../../images/s-bar.svg"
import CafeIcon from "../../images/s-cafe.svg"
import GastroPubIcon from "../../images/s-gastro-pub.svg"
import NightclubIcon from "../../images/s-nightclub.svg"
import PubIcon from "../../images/s-pub.svg"
import QuickServiceIcon from "../../images/s-quick-service.svg"
import RestaurantIcon from "../../images/s-restaurant.svg"

const tiles = [
  {name: "Bar Restaurant", icon: BarRestaurantIcon},
  {name: "Bar", icon: BarIcon},
  {name: "Cafe", icon: CafeIcon},
  {name: "Gastro Pub", icon: GastroPubIcon},
  {name: "Nightclub", icon: NightclubIcon},
  {name: "Pub", icon: PubIcon},
  {name: "Quick Service", icon: QuickServiceIcon},
  {name: "Restaurant", icon: RestaurantIcon},
]

const AboutYourBusiness = (props) => {

  return (
    <section className={SignUpStyles.signUp}>
      <div className={SignUpStyles.innerDiv}>

        <div className="component">
          <h1>Tell us about<br />your business</h1>
          <div className={SignUpStyles.subText}>What type of hospitality business are you operating?</div>
          <div className={SignUpStyles.emailForm}>
            <div className={`${SignUpStyles.formDiv} ${SignUpStyles.noMax}`}>
              <div className={`${SignUpStyles.tileWrapper} c-columns-4 c-columns-m-2 c-columns-gap-l `}>   
                {tiles.map((tile, index)=>{
                  return (
                  <label className={SignUpStyles.tile} key={`tile-${index}`} htmlFor={`tile-${index}`} >
                    <input type="checkbox" name="businessTypes" value={tile.name} id={`tile-${index}`} onChange={props.onCheckBoxChange} className={SignUpStyles.tileCheckbox} />
                    <div className={SignUpStyles.tileInner}>
                      <div className={SignUpStyles.innerInner}>
                        <img src={tile.icon} className={SignUpStyles.tileIcon} alt={tile.name} />
                      </div>
                      <div className={SignUpStyles.type}>{tile.name}</div>
                    </div>
                  </label>
                  )
                })}     
              </div>
              
              <div className={SignUpStyles.inputWrap}>        
                <input type="submit" value="Continue &rarr;" role="button" onClick={props.nextStep} /> 
                <div className={SignUpStyles.prevButton} role="button" onClick={props.prevStep} >
                  &larr; Go Back
                </div>            
              </div>
            </div>
          </div>
          <div className={SignUpStyles.disclaimer}>
            By creating a loaded account agree to the <Link to="/end-user-licence-agreement">End User Licence Agreement</Link> and <Link to="/privacy-policy">Privacy Policy.</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutYourBusiness;